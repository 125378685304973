.cart-container {
    padding: 20px;
}

.cart-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
}

.cart-table {
    width: 100%;
    border-collapse: collapse;
}

.cart-table th,
.cart-table td {
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.cart-table th {
    text-align: left;
}

.cart-table tfoot td {
    text-align: right;
}

.checkout-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.checkout-button:hover {
    background-color: #0056b3;
}