/* .heading {
    position: absolute;
    margin-left: 12%;
}

.banner {
    height: 33vh;
    width: 100%;
}

footer {
    background-color: #f4f4f4;
    padding: 10px;
    text-align: center;
} */

/* .prod{
    border: 2px solid red;
} */
/* .product{
    border: 2px solid green;
    width: 60%;
} */

/* .dropdown {
    width: 100%;
    margin-left: 12px;
    margin-right: 22px;
} */

.dropdown-menu.show {
    display: contents;
}
/* 
#myInput1 {
    padding: 20px;
    margin-top: 3px;
    border: 0;
    border-radius: 13px;
    background: #f1f1f1;
    position: absolute;
    height: 44px;
    width: 100%;

} */

.product-cardd {
    flex: 0 0 30%;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: left;

}

.heading {
    position: absolute;
    margin-left: 12%;
}

.banner {
    height: 33vh;
    width: 100%;
}

.product-list {
    display: flex;
    justify-content: space-between;
}

.product-card {
    flex: 0 0 30%;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
}

.product-card img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
}

.product-card h3 {
    margin-top: 10px;
}

.product-card p {
    color: #888;
}

.product-card button {
    margin-top: 10px;
}

footer {
    background-color: #f4f4f4;
    padding: 10px;
    text-align: center;
}