/* .divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
} */

.login{
    display: block;
    position: sticky;
    z-index: 0;
}