/* .resizedTextbox {
    width: 79%;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
} */

.header {
    overflow: hidden;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 100%;
}