.dashboard-container {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 600px;
    margin: 0 auto;
}

.user-info {
    margin-bottom: 20px;
}

.user-info h2 {
    color: #333;
}

.user-info p {
    margin: 5px 0;
}

.recent-orders {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.recent-orders h3 {
    margin-bottom: 10px;
    color: #333;
}

.recent-orders ul {
    list-style: none;
    padding: 0;
}

.recent-orders li {
    margin-bottom: 5px;
    color: #888;
}