/* .footer-bottom {
    position: static;
    margin-top: 30%;
} */

/* .footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 19rem;
    margin-top: 6.5rem;
} */

.icon {
    margin-left: 4%;
    /* font-size: 68px; */
}

.footer {
    display: flex;
    flex-direction: column;
    /* min-height: 35vh; */
}

/* .footer-bottom {
    margin-top: auto;
} */